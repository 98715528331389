import { ref, reactive, onMounted, nextTick, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
    TableData,
    SearchParams,
} from '@/views/SalesReporting/RVSubmission/OEM/types';
import * as API from '@/API/salesReporting';
import downloadFile from '@/utils/claim/downloadFile';
import { TreeDataItem } from 'ant-design-vue/lib/tree/Tree';
import moment from 'moment';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message } from 'ant-design-vue';
import { getRegion } from '@/API/salesReporting/claimCheckingReport';
import { findBrandByBu, getDealerByBUID } from '@/API/salesReporting';

type Key = ColumnProps['key'];

const useOEMPrepaymentQualified = () => {
    const select = ref();
    let defaultBu: string | undefined;
    const searchParams = reactive<SearchParams>({
        bu: defaultBu,
        brand: 'All',
        region: undefined,
        year: moment().format('YYYY'),
        qm: [moment().format('M')],
        dealerCodeCofico: null,
        dealerCodeSWT: null,
        dealerName: null,
        dealerGroup: null,
    });

    watch(
        () => searchParams.year,
        (newVal) => {
            if (!newVal) {
                searchParams.qm = [];
            }
        }
    );

    const qmOptions: TreeDataItem[] = [
        {
            title: 'Q1',
            value: 'Q1',
            key: 'Q1',
            children: [
                {
                    title: '1',
                    value: '1',
                    key: '1',
                },
                {
                    title: '2',
                    value: '2',
                    key: '2',
                },
                {
                    title: '3',
                    value: '3',
                    key: '3',
                },
            ],
        },
        {
            title: 'Q2',
            value: 'Q2',
            key: 'Q2',
            children: [
                {
                    title: '4',
                    value: '4',
                    key: '4',
                },
                {
                    title: '5',
                    value: '5',
                    key: '5',
                },
                {
                    title: '6',
                    value: '6',
                    key: '6',
                },
            ],
        },
        {
            title: 'Q3',
            value: 'Q3',
            key: 'Q3',
            children: [
                {
                    title: '7',
                    value: '7',
                    key: '7',
                },
                {
                    title: '8',
                    value: '8',
                    key: '8',
                },
                {
                    title: '9',
                    value: '9',
                    key: '9',
                },
            ],
        },
        {
            title: 'Q4',
            value: 'Q4',
            key: 'Q4',
            children: [
                {
                    title: '10',
                    value: '10',
                    key: '10',
                },
                {
                    title: '11',
                    value: '11',
                    key: '11',
                },
                {
                    title: '12',
                    value: '12',
                    key: '12',
                },
            ],
        },
    ];
    const activeTab = ref('unQulified');
    const tableData = ref<TableData[]>([]);

    const columns = [
        {
            title: 'Dealer Code(Cofico)',
            dataIndex: 'dealerConficoCode',
            width: 150,
        },
        {
            title: 'Dealer Code (SWT)',
            dataIndex: 'dealerSwtCode',
            width: 150,
        },
        {
            title: 'Dealer Name(CN)',
            dataIndex: 'dealerNameCn',
            width: 300,
        },
        {
            title: 'Dealer Name(EN)',
            dataIndex: 'dealerNameEn',
            width: 400,
        },
        {
            title: 'Dealer Group(CN)',
            dataIndex: 'dealerGroupCn',
            width: 200,
        },
        {
            title: 'Dealer Group(EN)',
            dataIndex: 'dealerGroupEn',
            width: 200,
        },
        {
            title: 'Sub Region',
            dataIndex: 'subRegion',
            width: 150,
        },
        {
            title: 'Business Unit',
            dataIndex: 'bu',
            width: 150,
        },
        {
            title: 'Year',
            dataIndex: 'year',
            width: 150,
        },
        {
            title: 'Quarter',
            dataIndex: 'quarter',
            width: 150,
        },
        {
            title: 'Month',
            dataIndex: 'month',
            width: 150,
        },
        {
            title: 'Eligible Retail No.',
            dataIndex: 'eligibleRetailNo',
            width: 150,
            slots: {
                customRender: 'eligibleRetailNo',
            },
        },
        {
            title: 'Submited Retail No.',
            dataIndex: 'submitedRetailNo',
            width: 150,
        },
        {
            title: 'Eligible Retail Passing Rate',
            dataIndex: 'eligibleRetailPassingRate',
            width: 200,
        },
        {
            title: 'Actual Retail No.',
            dataIndex: 'actualRetailNo',
            width: 150,
        },
        {
            title: 'Retail Submission Rate',
            dataIndex: 'retailSubmissionRate',
            width: 200,
        },
    ];

    const getSelectQm = () => {
        let q = '';
        const m: string[] = [];
        if (searchParams.qm) {
            searchParams.qm.forEach((item) => {
                const index = qmOptions.findIndex(
                    (o: TreeDataItem) => o.value === item
                );
                if (index !== -1) {
                    q += ',' + item;
                    const mList = qmOptions[index].children;
                    if (mList) {
                        for (let i = 0; i < mList.length; i++) {
                            m.push(mList[i].value);
                        }
                    }
                } else {
                    switch (item) {
                        case '1':
                        case '2':
                        case '3':
                            if (q.indexOf('Q1') === -1) q += ',Q1';
                            break;
                        case '4':
                        case '5':
                        case '6':
                            if (q.indexOf('Q2') === -1) q += ',Q2';
                            break;
                        case '7':
                        case '8':
                        case '9':
                            if (q.indexOf('Q3') === -1) q += ',Q3';
                            break;
                        case '10':
                        case '11':
                        case '12':
                            if (q.indexOf('Q4') === -1) q += ',Q4';
                            break;
                    }
                    m.push(item);
                }
            });
        }
        return { q: q.substr(1), m };
    };

    const store = useStore();
    //? 判断是不是region用户
    const isRegion = store.state.user.organization.entityCode === 'Region';
    const buList = computed(() => {
        return store.state.user.buList
            .filter((bu: any) => {
                return bu.parentId === null;
            })
            .filter((bu: any) => {
                return bu.nameEn !== 'Companycar' && bu.nameEn !== 'CPO';
            });
    });
    const getSelectBuId = () => {
        const index = buList.value.findIndex(
            (item: any) => item.nameEn === searchParams.bu
        );
        return buList.value[index]?.id;
    };
    // 后四个input 远程搜索
    const fetching = ref(false);
    const dealerGroupList = ref<any[]>();
    const coficoCodeList = ref<any[]>();
    const swtCodeList = ref<any[]>();
    const dealerNameCnList = ref<any[]>();
    const getDealerInfos = () => {
        const buId = getSelectBuId();
        getDealerByBUID({ buId }).then((res) => {
            const dealerGroups: any = {};
            const cofico: any[] = [];
            const swt: any[] = [];
            const nameCn: any[] = [];
            res.forEach((dealer: any) => {
                const dealerGroupEn = dealer.dealerGroup.dealerGroupEn;
                if (
                    dealerGroupEn &&
                    !Object.keys(dealerGroups).includes(dealerGroupEn)
                ) {
                    dealerGroups[dealerGroupEn] = {
                        label: dealerGroupEn,
                        value: dealer.dealerGroup.groupId,
                    };
                }
                cofico.push(dealer.dealerInfo.dealerCode);
                nameCn.push(dealer.dealerInfo.dealerNameCn);
                dealer.dealerInfo.swtCode &&
                    swt.push(dealer.dealerInfo.swtCode);
            });
            dealerGroupList.value = Object.values(dealerGroups);
            coficoCodeList.value = cofico;
            swtCodeList.value = swt;
            dealerNameCnList.value = nameCn;
        });
    };

    const brandList = ref<any[]>([]);
    const regionList = ref<any[]>([]);
    const handleBuChange = () => {
        const buId = getSelectBuId();
        //TODO 重置 region 两个dealer code dealerName dealer group
        searchParams.brand = 'All';
        searchParams.dealerCodeCofico = null;
        searchParams.dealerCodeSWT = null;
        searchParams.dealerName = null;
        searchParams.dealerGroup = null;
        getRegion({ buId: `${buId}` }).then((res) => {
            searchParams.region = undefined;
            // 北东西南 顺序排序
            const sortArr: any[] = [[], [], [], [], []];
            const maps = {
                n: 0,
                e: 1,
                s: 3,
                w: 2,
            };
            res.forEach((region: any) => {
                const firstLetter = (region
                    .regionEn[0] as string).toLocaleLowerCase();
                if (firstLetter in maps) {
                    sortArr[maps[firstLetter]].push(region);
                } else {
                    sortArr[4].push(region);
                }
            });
            regionList.value = sortArr.flat();
        });
        findBrandByBu({ buIds: [buId] }).then((res) => {
            if (res.length === 0) {
                brandList.value = [];
            } else {
                const bdList = res[0].brandList.filter((bu: any) => {
                    return bu.brandNameEn !== 'DENZA';
                });
                bdList.push({ brandNameEn: 'All' });
                brandList.value = bdList;
            }
        });
        getDealerInfos();
    };

    const doSearch = () => {
        if (!searchParams.year) {
            message.error('Retail period (Year) and BU must be selected!');
            return;
        }
        const { q, m } = getSelectQm();
        const buId = getSelectBuId();
        const params = {
            month: m,
            bu: searchParams.bu,
            buId,
            brand: searchParams.brand,
            region: searchParams.region,
            year: searchParams.year,
            quarters: q,
            cofico: searchParams.dealerCodeCofico,
            swt: searchParams.dealerCodeSWT,
            cn: searchParams.dealerName,
            group: searchParams.dealerGroup,
        };
        API.querySubmission(params).then((data) => {
            tableData.value = data;
        });
    };

    const searchClick = () => {
        doSearch();
    };

    const resetClick = () => {
        Object.assign(searchParams, {
            bu: defaultBu,
            brand: undefined,
            region: undefined,
            year: moment().format('YYYY'),
            qm: [moment().format('M')],
            dealerCodeCofico: null,
            dealerCodeSWT: null,
            dealerName: null,
            dealerGroup: null,
        });
    };

    const tabChange = () => {
        doSearch();
    };

    const openYear = ref(false);
    const handlerOpenChange = (status: any): void => {
        openYear.value = status;
    };
    const handlerPanelChange = (value: string): void => {
        const time = moment(value).format('YYYY');
        searchParams.year = time as string;
        openYear.value = false;
    };

    const chartVisible = ref(false);
    const openChart = () => {
        chartVisible.value = true;
    };
    const closeChart = () => {
        chartVisible.value = false;
    };

    const downloadColumnsData = [{ title: 'File', dataIndex: 'file' }];
    const downloadTableData = [
        {
            file: 'RV Submission Report',
            fileType: 'form',
        },
        {
            file: 'RV Submission Report Raw Data',
            fileType: 'detail',
        },
    ];
    const downloadRowSelection = reactive<{
        selectedRowKeys: Key[];
        selectedRowData: any[];
        onChange: Function;
    }>({
        onChange: (selectedRowKeys: Key[], selectedRows: any[]) => {
            downloadRowSelection.selectedRowKeys = selectedRowKeys;
            downloadRowSelection.selectedRowData = selectedRows;
        },
        selectedRowData: [],
        selectedRowKeys: [],
    });
    const downloadVisible = ref(false);
    const openDownload = () => {
        downloadVisible.value = true;
    };
    const closeDownload = () => {
        downloadVisible.value = false;
        downloadRowSelection.selectedRowData = [];
        downloadRowSelection.selectedRowKeys = [];
    };
    const downloadClick = () => {
        if (downloadRowSelection.selectedRowKeys.length < 1) {
            message.warning('Please select the data first');
            return;
        }
        const { q, m } = getSelectQm();
        const buId = getSelectBuId();
        let type;
        if (downloadRowSelection.selectedRowKeys.length > 1) {
            type = 'zip';
        } else {
            type = downloadRowSelection.selectedRowKeys[0];
        }
        const params = {
            url: '/rvapi/report/download/submission',
            method: 'get',
            data: {},
            params: {
                month: m,
                bu: searchParams.bu,
                buId,
                brand: searchParams.brand,
                region: searchParams.region,
                year: searchParams.year,
                quarters: q,
                cofico: searchParams.dealerCodeCofico,
                swt: searchParams.dealerCodeSWT,
                cn: searchParams.dealerName,
                group: searchParams.dealerGroup,
                downFlag: type,
            },
        };
        downloadFile(params, '');
    };

    const initValue = (value: string) => {
        searchParams.bu = value;
        defaultBu = value;
    };
    const dogetDefault = () => {
        if (buList.value.length === 0) return;
        const mbIndex = buList.value.findIndex((i: any) => {
            return i.nameEn === 'MBPC';
        });
        const initIndex = mbIndex === -1 ? 0 : mbIndex;
        searchParams.bu = buList.value[initIndex].nameEn;
        defaultBu = buList.value[initIndex].nameEn;
    };
    dogetDefault();

    const route = useRoute();
    const routeInit = () => {
        const query = route.query;
        if (Object.keys(query).length > 0) {
            searchParams.bu = buList.value.find((bu: any) => {
                return `${bu.id}` === (route.query.bu as string);
            }).nameEn;
            searchParams.brand = route.query.brand as string;
            searchParams.year = route.query.year as string;
            if (route.query.quarter) {
                searchParams.qm = Array.from(route.query.quarter).map((q) => {
                    return `Q${q as string}`;
                });
            }
        }
    };

    const router = useRouter();
    const routerJump = () => {
        const routeMap = {
            SICCO: '/retailValidation/sicco',
            'Sales Incentive Program Owner': '/retailValidation/sipo',
        };
        const role = store.state.user.organization.entityCode;
        router.push({ path: routeMap[role] });
    };

    onMounted(() => {
        handleBuChange();
        nextTick(() => {
            routeInit();
            doSearch();
        });
    });

    return {
        isRegion,
        getSelectBuId,
        searchParams,
        qmOptions,
        activeTab,
        tableData,
        columns,
        searchClick,
        resetClick,
        downloadClick,
        tabChange,
        openYear,
        handlerOpenChange,
        handlerPanelChange,
        chartVisible,
        openChart,
        closeChart,
        downloadVisible,
        openDownload,
        closeDownload,
        downloadColumnsData,
        downloadTableData,
        downloadRowSelection,
        select,
        initValue,
        brandList,
        regionList,
        handleBuChange,
        buList,
        getSelectQm,
        routerJump,
        fetching,
        dealerGroupList,
        coficoCodeList,
        swtCodeList,
        dealerNameCnList,
    };
};
export default useOEMPrepaymentQualified;
