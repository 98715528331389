
import { defineComponent, nextTick, ref, watch } from 'vue';
import * as echarts from 'echarts';
import useRVSubmission from '@/hooks/SalesReporting/RVSubmission/OEM/useRVSubmission';
import { queryHistogram } from '@/API/salesReporting';

export default defineComponent({
    props: {
        modalVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        params: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    emits: ['close-modal'],
    setup(props, { emit }) {
        const { getSelectQm, getSelectBuId } = useRVSubmission();
        const total: any[] = [];
        const eligibleRetail: any[] = [];
        const eligibleRetailPassingRate: any[] = [];
        const ineligibleRetail: any[] = [];
        const getShowData = () => {
            const { q, m } = getSelectQm();
            const buId = getSelectBuId();
            const { bu, brand, region, year } = props.params;
            const params = {
                month: m,
                bu,
                buId,
                brand,
                region,
                year,
                quarters: q,
            };
            return queryHistogram(params).then((res: any) => {
                res.forEach((element: any) => {
                    total.push(element.total);
                    ineligibleRetail.push(element.ineligible);
                    eligibleRetail.push(element.eligibleRetailNo);
                    eligibleRetailPassingRate.push(
                        element.eligibleRetailPassingRate.replace('%', '')
                    );
                });
            });
        };
        const closeModal = () => {
            emit('close-modal');
        };

        const chartRef = ref<HTMLElement>();
        const myChart = ref<any>();

        const showChart = () => {
            if (chartRef.value) {
                // 绘制图表
                myChart.value = echarts.init(chartRef.value);
                myChart.value.setOption({
                    xAxis: {
                        type: 'category',
                        data: [
                            'Jan',
                            'Feb',
                            'Mar',
                            'Apr',
                            'May',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Oct',
                            'Nov',
                            'Dec',
                        ],
                        axisTick: {
                            show: false,
                        },
                    },
                    yAxis: [
                        {
                            type: 'value',
                            show: false,
                        },
                        {
                            type: 'value',
                            show: false,
                            min: 0,
                            max: 100,
                        },
                    ],
                    legend: {
                        // x: 'right',
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    label: {
                        show: true,
                        position: 'top',
                    },
                    series: [
                        {
                            name: 'Ineligible Retail No.',
                            data: ineligibleRetail,
                            type: 'bar',
                            zlevel: 1,
                            barWidth: 20,
                            color: '#00ADEF',
                            label: {
                                color: '#0088C6',
                            },
                        },
                        {
                            name: 'Eligible Retail No.',
                            data: eligibleRetail,
                            type: 'bar',
                            barGap: '-100%',
                            barWidth: 20,
                            color: '#7FD6F7',
                            label: {
                                color: '#7FD6F7',
                                formatter: (params: any) => {
                                    return total[params.dataIndex];
                                },
                            },
                        },
                        {
                            name: 'Eligible Retail Passing Rate',
                            data: eligibleRetailPassingRate,
                            type: 'line',
                            yAxisIndex: 1,
                            color: '#FFBA3E',
                            label: {
                                color: '#FFBA3E',
                                formatter: '{c}%',
                            },
                        },
                    ],
                });
            }
        };

        watch(
            () => props.modalVisible,
            (val) => {
                if (val) {
                    getShowData().then(() => {
                        nextTick(showChart);
                    });
                }
            }
        );

        return {
            closeModal,
            chartRef,
        };
    },
});
